import React from 'react'
import { graphql, Link } from 'gatsby'

import EpisodeCard from '../components/episode-card'
import Layout from '../components/layout'

export const query = graphql`
    query {
        allEpisodesJson(sort: {order: [ASC, ASC], fields: [season, episode]},
            filter: {season: {eq: 1}, episode: {lte: 3}}) {
            nodes {
                title
                show
                season
                episode
                fields {
                    slug
                    url
                    thumbnailUrl
                }
            }
        }
        episodesCount: allEpisodesJson {
            group(field: show) {
                totalCount
                fieldValue
            }
        }
        allShowsJson {
            nodes {
                name
                slug
            }
        }
    }
`
const HomePage = ({data}) => {

    return (
        <Layout>
            {data.allShowsJson.nodes.map(show => {
                var episodes = data.allEpisodesJson.nodes.filter(episode => episode.show === show.slug)
                var episodesCount = data.episodesCount.group.filter(group => group.fieldValue === show.slug)[0].totalCount

                var lastCard;
                if (episodesCount > 4) {
                    lastCard = <div class="col-sm-6 col-md-4 col-lg-3 episode-card">
                        <Link to={`/${show.slug}`}>
                            <div class="thumbnail background-16-9"><div></div></div>
                            <div class="series-name">{show.name}</div>
                            <h5>{episodesCount} épisodes</h5>
                        </Link>
                    </div>
                }

                return [
                    <h3>{show.name}</h3>,
                    <div class="row">
                        {episodes.map(episode =>
                            <EpisodeCard episode={episode} show={show} />
                        )}
                        {lastCard}
                    </div>
                ]
            }
                
            )}
        </Layout>
    )
}
    
export default HomePage